@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Roboto, sans-serif;
  }
}
:root {
  --form-control-color: #f2c773;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari, and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }


    /* Hide scrollbar for IE, Edge, and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: var(--form-control-color);
  width: 1.15em;
  height: 1.15em;
  border: 0.12em solid var(--form-control-color);
  border-radius: 50%;
  display: grid;
  place-content: center;
}
input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}
input[type="radio"]:checked::before {
  transform: scale(1);
}
/* input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid var(--form-control-color);
  outline-offset: max(2px, 0.15em);
} */
.bg-user-type {
  background-image: url("../src/assets/Images/bg_user_type.svg");
}
.bg-user-type-two {
  background-image: url("../src/assets/Images/bg_user_type_two.svg");
  background-size: cover;
}

.bg-imag-dash {
  background-color: #d3eff1;
  background-image: url("../src/assets/Images/background_pattern.svg");
}
.bg-imag-dash-2 {
  background-color: #d3eff1;
  background-image: url("../src/assets/Images/bg_pattern_2.svg");
}

.bg-pop {
  background-image: url("../src/assets/Images/image.jpg");
  /* filter: blur(1px);
  -webkit-filter: blur(1px); */
}

.bg-drop-icon {
  background-image: url("../src/assets/Icons/Dashboard/dropdown_icon.svg");
}

.border-bt {
  border: 1;
  border-color: red;
  /* border-bottom: red 1px; */
  /* border-bottom: red; */
}

.hidden {
  /* display: none !important; */
}

#installContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

#installContainer button {
  border: 1px solid white;
  color: white;
  font-size: 1em;
  padding: 0.75em;
}
