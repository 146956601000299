:root {
  --ring1: #e9b44c;
  --ring2: #64c8ce;
}

.circular_progress {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #86939b;
}
.RCP__progress {
  background: linear-gradient(to right, #ff8a00, #da1b60) !important;
}
.circular_svg {
  height: 70px;
  width: 70px;
}

.value_container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin: 0 auto;
  font-size: 2.2em;
  font-weight: 100;
  color: #fff;
  user-select: none;
  flex-direction: column;
}
.pointer {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e9b44c;
  position: absolute;
  display: flex;
  align-self: flex-start;
  top: -6px;
}
.day {
  color: #ddedee;
  font-weight: 200;
  font-size: 12px;
  margin-top: 10px;
}
.count {
  color: #ddedee;
  font-size: 18px;
  font-weight: 800;
}
.absolute_center {
  position: absolute;
  top: 60%;
  left: 51%;
  transform: translate(-55%, -51%);
}

.progress_container {
  position: relative;
  width: 70px; /* Adjust width as needed */
  height: 70px; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress_container svg {
  width: 85px !important;
  height: 85px !important;
}
.progress_container circle.RCP__progress {
  margin-bottom: 40px !important;
  width: 100% !important;
  height: 100% !important;
  background-color: #3a5363 !important;
  border-radius: 15px !important;
}
