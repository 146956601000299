/* .inputField {
  background: #ffffff;
  border: 1px solid #f0feff;
  box-shadow: 0px 8px 24px rgba(0, 86, 92, 0.15);
  border-radius: 5px;
} */

input[type=range].styled-slider {
  height: 9px;
  -webkit-appearance: none;
}

/*progress support*/
input[type=range].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 17px + var(--ratio) * (100% - 17px));
}

input[type=range].styled-slider:focus {
  outline: none;
}

/*webkit*/
input[type=range].styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 17px;
  height: 17px;
  border-radius: 8px;
  background: #64C8CE;
  border: none;
  box-shadow: 0 0 2px #DEDEDE;
  margin-top: calc(9px * 0.5 - 17px * 0.5);
}

input[type=range].styled-slider::-webkit-slider-runnable-track {
  height: 9px;
  border: none;
  border-radius: 0.5em;
  background: #F0FEFF;
  box-shadow: none;
}

input[type=range].styled-slider::-webkit-slider-thumb:hover {
  background: #75DFE5;
}

input[type=range].styled-slider:hover::-webkit-slider-runnable-track {
  background: #F0FEFF;
}

input[type=range].styled-slider::-webkit-slider-thumb:active {
  background: #75DFE5;
}

input[type=range].styled-slider:active::-webkit-slider-runnable-track {
  background: #F0FEFF;
}

input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#64C8CE,#64C8CE) 0/var(--sx) 100% no-repeat, #64C8CE;
}

input[type=range].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
  background: linear-gradient(#81EAF0,#81EAF0) 0/var(--sx) 100% no-repeat, #64C8CE;
}

input[type=range].styled-slider.slider-progress:active::-webkit-slider-runnable-track {
  background: linear-gradient(#81EAF0,#81EAF0) 0/var(--sx) 100% no-repeat, #64C8CE;
}

/*mozilla*/
input[type=range].styled-slider::-moz-range-thumb {
  width: 17px;
  height: 17px;
  border-radius: 8px;
  background: #64C8CE;
  border: none;
  box-shadow: 0 0 2px #DEDEDE;
}

input[type=range].styled-slider::-moz-range-track {
  height: 9px;
  border: none;
  border-radius: 0.5em;
  background: #F0FEFF;
  box-shadow: none;
}

input[type=range].styled-slider::-moz-range-thumb:hover {
  background: #75DFE5;
}

input[type=range].styled-slider:hover::-moz-range-track {
  background: #F0FEFF;
}

input[type=range].styled-slider::-moz-range-thumb:active {
  background: #75DFE5;
}

input[type=range].styled-slider:active::-moz-range-track {
  background: #F0FEFF;
}

input[type=range].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient(#64C8CE,#64C8CE) 0/var(--sx) 100% no-repeat, #F0FEFF;
}

input[type=range].styled-slider.slider-progress:hover::-moz-range-track {
  background: linear-gradient(#81EAF0,#81EAF0) 0/var(--sx) 100% no-repeat, #F0FEFF;
}

input[type=range].styled-slider.slider-progress:active::-moz-range-track {
  background: linear-gradient(#81EAF0,#81EAF0) 0/var(--sx) 100% no-repeat, #F0FEFF;
}

/*ms*/
input[type=range].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-thumb {
  width: 17px;
  height: 17px;
  border-radius: 8px;
  background: #64C8CE;
  border: none;
  box-shadow: 0 0 2px #DEDEDE;
  margin-top: 0;
  box-sizing: border-box;
}

input[type=range].styled-slider::-ms-track {
  height: 9px;
  border-radius: 0.5em;
  background: #F0FEFF;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
}

input[type=range].styled-slider::-ms-thumb:hover {
  background: #75DFE5;
}

input[type=range].styled-slider:hover::-ms-track {
  background: #F0FEFF;
}

input[type=range].styled-slider::-ms-thumb:active {
  background: #75DFE5;
}

input[type=range].styled-slider:active::-ms-track {
  background: #F0FEFF;
}

input[type=range].styled-slider.slider-progress::-ms-fill-lower {
  height: 9px;
  border-radius: 0.5em 0 0 0.5em;
  background: #64C8CE;
  border: none;
  border-right-width: 0;
}

input[type=range].styled-slider.slider-progress:hover::-ms-fill-lower {
  background: #81EAF0;
}

input[type=range].styled-slider.slider-progress:active::-ms-fill-lower {
  background: #81EAF0;
}
