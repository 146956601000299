.share_btn {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 7px 30px 8px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: #f2c773;
  color: #ffffff;
  border-radius: 40px;
}
.share_btn2 {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 7px 30px 8px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: #e9e9e9;
  color: black;
  border-radius: 40px;
  margin-top: 10px;
}
.shukranID {
  font-size: 20px !important;
  font-weight: 900;
  color: #50a2a7;
  font-style: italic;
}
@media screen and (max-width: 348px) {
  .shukranID {
    font-size: 15px !important;
  }
}
