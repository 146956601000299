.amountInput {
  width: 100%;
  height: 50px;
  border-radius: 40px;
  align-self: center;
  padding: 10px;
  ::placeholder {
    color: "lightgray";
    font-size: 12px;
    padding: 10px;
  }
}
