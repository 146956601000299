.wrapper {
  margin: 20px 24px;
}
.skip_link {
  display: flex;
  align-self: flex-end;
  color: #767676;
  position: absolute;
  top: 0;
}
.gif {
  width: 300px;
  height: 300px;
  margin-top: 1.75rem;
  display: flex;
  align-self: center;
}
