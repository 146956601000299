.add_member {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 60px;
  border: 1px solid #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  border-radius: 24px;
}
.add {
  width: 20px;
  height: 20px;
}
.chevron {
  width: 15px;
  height: 15px;
}
.add_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.desctxt {
  color: #e0e0e0;
  font-size: 16px;
}
