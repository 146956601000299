@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&family=Playfair+Display:wght@800&display=swap");

* {
  font-family: "Nunito", sans-serif;
  color: #404b5a;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.Page {
  justify-self: unset;
  height: 100vh;
}
/* Header*/

.Header_text {
  font-size: 0.875rem;
}
.Header_text_caps {
  text-transform: uppercase;
}
/* .Profile_Icon {
  width: 59px;
  height: 59px;
} */
.blue_circle {
  color: #fff;
  background-color: #64c8ce;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
/* Account*/
.AccountBalance_Value {
  font-family: "Playfair Display", serif;
}
/*Tipping*/
.circle {
  @apply w-24 h-24 rounded-full flex flex-col relative bg-accent items-center justify-center;
  /* height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #f2c773;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; */
}

.circle:before,
.circle:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #f2c773;
  border-radius: 50%;
}

.circle:before {
  animation: ripple 2s linear infinite;
}

.circle:after {
  animation: ripple 2s linear 1s infinite;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  /* 25% {
    transform: scale(1.4);
    opacity: 1;
  } */
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  /* 75% {
    transform: scale(2.3);
    opacity: 1;
  } */
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}
.circle_content_centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Upper_Card {
  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(17, 33, 34, 0.02);
  border-radius: 30px 30px 0px 0px;
  z-index: 3;
}
.smallCircle {
  height: 68px;
  width: 68px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Circular_Button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Small_Button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/*Reusable Sytles*/
.text_small {
  font-size: 0.875rem;
  color: #929fb1;
}
