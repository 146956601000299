.customAmount {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #d6dbe2;
  padding: 10px;
  ::placeholder {
    color: "lightgray";
    font-size: 12px;
    padding: 10px;
  }
}
